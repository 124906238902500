<template>
  <div v-if="items !== null && items !== undefined && originalItems.length > 0">
    <v-row>
      <v-col cols="12" sm="10" class="pb-0">
        <v-select
          chips
          deletable-chips
          multiple
          :items="fields"
          v-model="selectedFields"
        />
      </v-col>
      <v-col cols="12" sm="2" class="text-center my-2 pb-0">
        <v-btn
          color="primary"
          x-large
          :disabled="modifiedItems.length <= 0"
          @click="saveBulk"
        >
          {{ $t("save") }}
        </v-btn>
      </v-col>
      <v-col cols="12" class="pa-0" v-if="modifiedItems.length > 0">
        <p class="subtitle-2 text-center">
          {{ $t("youHave") }} {{ modifiedItems.length }}
          {{ $t("unsavedItems") }}.
        </p>
      </v-col>
    </v-row>
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item v-for="tab in tabs" :value="tab.key" :key="tab.key">
        <BulkEditItemList
          v-if="tab.key === 'active'"
          :items="activeItems"
          :original-items="originalItems"
          :modified-items="modifiedItems"
          :selected-fields="selectedFields"
          @itemsChanged="updateModifiedItems"
        />
        <BulkEditItemList
          v-else-if="tab.key === 'inactive'"
          :items="inactiveItems"
          :original-items="originalItems"
          :modified-items="modifiedItems"
          :selected-fields="selectedFields"
          @itemsChanged="updateModifiedItems"
        />
        <BulkEditItemList
          v-else
          :items="itemsInNamespace(tab.key)"
          :original-items="originalItems"
          :modified-items="modifiedItems"
          :selected-fields="selectedFields"
          @itemsChanged="updateModifiedItems"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import BulkEditItemList from "@/components/BulkEditItemList"

export default {
  name: "BulkEdit",
  components: {
    BulkEditItemList,
  },
  data: function () {
    return {
      showAddItemDialog: false,
      showItemDialog: false,
      selectedItemType: null,
      fields: [
        "thumbnail",
        "title",
        "price",
        "sale_price",
        "cost_price",
        "current_stock",
        "target_stock",
        "categories",
        "status",
        "sku"
      ],
      selectedFields: ["thumbnail", "title", "price", "categories", "status"],
      modifiedItems: [],
      originalItems: [],
    }
  },
  methods: {
    updateModifiedItems(value) {
      this.modifiedItems = JSON.parse(JSON.stringify(value))
    },
    itemTypeDialogClosed() {
      this.showAddItemDialog = false
    },
    itemDialogClosed() {
      this.showItemDialog = false
    },
    selectItemType(itemType) {
      this.selectedItemType = itemType
      this.showAddItemDialog = false
      this.showItemDialog = true
    },
    saveItem({ item }) {
      return this.$store
        .dispatch("bulkUpdateItem", item)
        .then(() => {
          return {
            success: true,
            message: this.$t("thisIsSuccessful"),
          }
        })
        .catch(() => {
          return {
            success: false,
            message: this.$t("errorOccurred"),
          }
        })
    },
    saveBulk() {
      this.$store.commit("updateBadgeProcess", {
        isVisible: true,
        process: this.saveItem,
        items: this.modifiedItems,
        onFinish: function () {
          this.modifiedItems = []
        }.bind(this),
      })
    },
    itemsInNamespace(namespace) {
      if (this.items === null) {
        return []
      }

      return this.items.filter((item) => {
        return item.type === namespace
      })
    },
  },
  watch: {
    items(newItems, oldItems) {
      if (newItems !== null && newItems !== undefined && newItems.length > 0) {
        this.$store.commit("updateAppBarTabs", this.tabs)

        this.items.forEach((theItem) => {
          if (theItem.sale_price === null) {
            theItem.sale_price = {
              amount: null,
              currency: null,
            }
          }
          if (theItem.cost_price === null) {
            theItem.cost_price = {
              amount: null,
              currency: null,
            }
          }
          if (theItem.availability === null) {
            theItem.availability = {
              current: null,
              target: null,
            }
          }
        })

        if (this.originalItems.length === 0) {
          this.originalItems = JSON.parse(
            JSON.stringify(this.$store.state.items)
          )
        }
      }
    },
  },
  computed: {
    query: function () {
      return this.$store.state.searchQuery
    },
    activeItemsCount: function () {
      if (this.activeItems !== null) {
        return this.activeItems.length
      }

      return 0
    },
    inactiveItemsCount: function () {
      if (this.inactiveItems !== null) {
        return this.inactiveItems.length
      }

      return 0
    },
    items() {
      if (this.$store.state.items === null) {
        return []
      }
      if (
        this.query !== null &&
        this.query !== undefined &&
        this.query.length > 0
      ) {
        const query = this.query.reduce(
          (accumulator, item) => accumulator + item.value,
          ""
        )
        return this.$store.state.items.filter((item) =>
          item.title.toLowerCase().includes(query.toLowerCase())
        )
      }

      return this.$store.state.items
    },
    inactiveItems() {
      if (this.items === null || this.items === undefined) {
        return null
      }

      return this.items.filter((item) => {
        return !item.status
      })
    },
    activeItems() {
      if (this.items === null || this.items === undefined) {
        return null
      }

      return this.items.filter((item) => {
        return item.status
      })
    },
    selectedAppBarTab: function () {
      return this.$store.state.selectedAppBarTab
    },
    itemNamespaces() {
      const itemTypesOfItems = []

      for (let i in this.items) {
        const namespace = this.items[i].type
        if (!itemTypesOfItems.includes(namespace)) {
          itemTypesOfItems.push(namespace)
        }
      }

      return itemTypesOfItems
    },
    tabs() {
      const tabs = []

      tabs.push({
        title: this.$t("active"),
        key: "active",
        badge: {
          color: "green",
          content: this.activeItemsCount,
        },
      })

      tabs.push({
        title: this.$t("inactive"),
        key: "inactive",
        badge: {
          color: "red",
          content: this.inactiveItemsCount,
        },
      })

      if (this.items !== null) {
        for (let i in this.itemNamespaces) {
          const namespace = this.itemNamespaces[i]
          tabs.push({
            title: namespace,
            key: namespace,
          })
        }
      }

      return tabs
    },
  },
  created() {
    this.$store.commit("updateAppBarTabs", this.tabs)
    this.$store.commit("updateSelectedAppBarTab", this.tabs[0].key)
    window.onbeforeunload = () => (this.modifiedItems.length > 0 ? true : null)
  },
  mounted() {
    this.$store.commit("updateAppBarTabs", this.tabs)
    this.$store.commit("updateSelectedAppBarTab", this.tabs[0].key)
    window.onbeforeunload = () => (this.modifiedItems.length > 0 ? true : null)
  },
  destroyed() {
    this.$store.commit("updateAppBarTabs", [])
  },
}
</script>
